.container-contact{
    background-color: var(--bg-transparent);
    width: 350px;
}

.container-text{
    color: black;
}

.container-image{
    height: 250px;
    width: 250px;
}

.container-circle{
    background-color: #b7b7b7;
    width: 40px;
    height: 40px;
}

@media (min-width: 768px){
    .container-contact{
        width: 700px;
    }
    .container-image{
        height: 280px;
        width: 280px;
    }
}

@media (min-width: 992px){
    .container-contact{
        width: 900px;
        gap: 30px;
    }
    .container-image{
        height: 300px;
        width: 300px;
    }
}