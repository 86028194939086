.col{
    display: flex;
    justify-content: center;
}

.container-cards{
    margin-top: 150px;
}

@media (min-width: 767px) and (max-width: 992px){
    .card-2{
        margin-top: 0px;
    }
    .card-3{
        margin-top: 190px!important;
    }
}

@media (min-width: 320px) and (max-width: 767px){
    .card-2, .card-3{
        margin-top: 170px!important;
    }
    
}