.navbar-toggler{
  border: none !important;
}

.nav-link{
  padding-left: .25rem !important;
  padding-right: .25rem !important;
  margin-left: .25rem;
  margin-right: .25rem;
  padding-bottom: .25rem !important;
  margin-bottom: .25rem !important;
  border-bottom: #00000060 solid 0px !important;
}

.navbar-toggler:hover,
.navbar-toggler:focus,
.navbar-toggler:active{
  border: none !important;
  box-shadow: none !important;
}

.active{
  border-bottom: #00000060 solid 1px !important;
}

@media (max-width: 992px) {
    .NavResponsive{
        display: flex;
        justify-content: space-between;
        width: 100vw;
    }
    
}