*{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

:root {
    --bg-green: #CDD5C6;
    --bg-light: #fff7f0;
    --bg-grey:  #B7BCBF;
    --bg-brown: #D7D0C2;
    --bg-orange:#ff9451;
    --bg-transparent:#f5f5f567;
}