.carousel-inner{
  height: 100%;
}

.img-container{
  position: relative;
  width: 100%;
  height: 100%;
}

.img-container img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}