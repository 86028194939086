.container-img {
  width: 100%;
  box-shadow: 0px 17px 27px -5px rgba(0, 0, 0, 0.1);
}
.img-map{
 position: relative;
}
.position-img {
  object-position: center;
}

.container-countries {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 400px;
  background-color: var(--bg-transparent);
}



@media (min-width: 768px){
    .container-img {
        width: 700px;
        height: 400px;
      }
}

@media (min-width: 992px){
  .container-img {
    width: 800px;
    height: 500px;
  }
}