.size-row{
  width: 800px;
  background-color: var(--bg-transparent);
  padding-top: 25px;
  padding-bottom: 25px;
}
.size-image{
  width: 350px;
  height: 350px;
}

@media (min-width: 768px) and (max-width: 992px){
  .texto {
    width: 500px!important ;
  }
  .size-row{
    width: 700px;
  }
}

@media (min-width: 425px) and (max-width: 768px){
  .texto {
    width: 370px!important ;
  }
  .size-row{
    width: 420px;
  }
  .container-text{
    padding-top: 20px;
  }
}

@media (min-width: 320px) and (max-width: 425px){
  .texto {
    width: 300px!important ;
  }
  .size-row{
    width: 350px;
  }
  .container-text{
    padding-top: 20px;
  }
  .size-image{
    width: 300px;
    height: 300px;
  }


}