.image-container {
  width: 400px;
  height: 400px;
}

.image-Quienes-somos {
  object-position: center;
}

.blur {
  background-color: var(--bg-transparent);
  width: 450px !important;
}

.rounded-text {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.rounded-img {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.text p {
  width: 400px;
}

@media (min-width: 766px) and (max-width: 992px) {
  .rounded-text {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .rounded-img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 10px;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .text {
    padding-top: 30px;
  }
  .rounded-img {
    padding-top: 30px;
  }
  .rounded-text {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .rounded-img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 10px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .image-container {
    width: 300px;
    height: 300px;
  }
  .text {
    justify-content: center;
    display: flex;
    padding-top: 50px;
  }

  .text p {
    width: 350px;
  }
  .rounded-text {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .rounded-img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 10px;
  }
}
