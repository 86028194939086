.container-img-collection {
  width: 280px;
  height: 310px;
  position: relative;
}

.container-card {
  background-color: var(--bg-transparent);
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.position-image {
  object-position: center;
  position: absolute;
}

.Container-text {
  margin-top: -100px;
  padding: 0 2rem;
}

.Container-text h1{
    font-size: 25px;
}

@media (min-width: 768px){
  .container-card{
    width: 340px;
  }
  .container-img-collection {
    width: 250px;
    height: 280px;
  }
}

@media (min-width: 991px) {
    .container-img-collection {
      width: 250px;
      height: 280px;
    }
  
    .container-card{
      width: 300px;
    }
  }

  @media (min-width: 1200px) {
    .container-img-collection {
      width: 300px;
      height: 330px;
    }
  
    .container-card{
      width: 360px;
    }
  }