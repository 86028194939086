#Layout{
    background-image: url("../../assets/fondo.jpg");
    height: 100vh;
    overflow-y: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-x: hidden;
}

#Layout::-webkit-scrollbar {
  width: 12px;
}

#Layout::-webkit-scrollbar-thumb {
  background-color: var(--bg-green) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
